import rsFlag from "./images/rs-flag.png";
import usFlag from "./images/us-flag.png";
import React from "react";

export const options = [
    {id: 0, short: "RS", name: "Srpski", value: "/", label: <span><img src={rsFlag} width={20} alt="RS zastava" /> RS </span>},
    {id: 1, short: "РС", name: "Српски", value: "/насловна", label: <span><img src={rsFlag} width={20} alt="RS zastava" /> РС </span>},
    {id: 2, short: "EN", name: "English", value: "/home", label: <span><img src={usFlag} width={20} alt="US zastava" /> EN </span>}
];
export const momentOverride = [{
    months : [
        "Januar", "Februar", "Mart", "April", "Maj", "Jun", "Jul",
        "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"
    ],
    weekdays: ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
    weekdaysShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
}];


